@import "../../../styles/variables.scss";

.chat-logs {
  max-height: 500px;
  overflow-y: auto;

  .chat-log {
    font-family: $primary-font;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .body {
      max-width: 300px;
      display: block;
      padding: 15px;
      border-radius: 15px;
      background-color: $gray-fade;
      margin: 10px 0;
    }

    .media {
      display: block;
      cursor: pointer;

      .media-img {
        width: auto;
        height: auto;
        max-width: 150px;
        max-height: 150px;
        border-radius: 5px;
      }

      .attach {
        display: inline-block;
        font-family: inherit;
        font-style: italic;
        background-color: $gray-fade;
        border-radius: 10px;
        padding: 15px;
      }
    }

    .datetime {
      font-size: 13px;
      font-weight: 400;
      font-family: inherit;
    }

    &.sender {
      align-items: flex-end;

      .body {
        background-color: $primary-color;
        color: #fff;
      }

      .media {
        .attach {
          background-color: $primary-color;
          color: #fff;
        }
      }
    }
  }
}
