@import "../../../styles/_variables";
@import "../../../SiteShared.scss";

.media {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .image {
    display: block;
    max-width: 250px;
    margin: 5px;
  }

  .file-wrapper {
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin: 5px;

    .file-icon {
      display: flex;
      width: 60px;
      height: 60px;
      background-color: rgba(23, 26, 29, 0.25);
      margin: 0 2px;
      align-items: center;
      justify-content: center;
      border-radius: 3px;

      img {
        max-width: 40px;
        max-height: 40px;
      }
    }
    .file-info {
      display: flex;
      flex-direction: column;
      margin: 0 10px;
    }
  }
}
