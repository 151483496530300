.add-property-container {
  background-color: unset !important;
  .badge {
    box-shadow: none;
    padding: 0.25rem 0.45rem !important;
  }
  .card-image-box {
    .input-property-photo {
      display: none;
    }
  }
  .appliance-button-add {
    height: 2.75rem;
    border-top-right-radius: 0.4673rem !important;
    border-bottom-right-radius: 0.4673rem !important;
  }
}
