@import "../../../styles/_variables";
@import "../../../SiteShared.scss";

.message-type-select {
  font-family: "BeVietnam", sans-serif;
  cursor: pointer;
  position: relative;
  min-width: 150px;
  width: 200px;

  .active-type {
    background-color: rgba(
      red($gray-dark),
      green($gray-dark),
      blue($gray-dark),
      0.05
    );
    font-size: 13px;
    font-weight: 700;
    height: 35px;
    line-height: 35px;
    text-align: center;
    padding: 0 10px;

    .icon {
      margin-left: 10px;
    }
  }

  .options {
    position: absolute;
    min-width: 150px;
    bottom: 35px;
    border-radius: 3px;
    background-color: #fff;
    padding: 20px 25px;
    width: 100%;
    box-shadow: 0 11px 14px -10px #e5eced;

    .option {
      height: 35px;
      line-height: 35px;
      color: $gray-dark;
      font-size: 13px;
      font-weight: 400;
    }
  }

  .disable-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

@include for-phone {
  .message-type-select {
    width: 100%;

    .active-type {
      text-align: left;
      span {
        display: inline-block;
        width: calc(100% - 30px);
      }
    }

    .options {
      padding: 10px;
    }
  }
}
