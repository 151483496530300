@import "../../styles/quillpro/quillpro-variables";
@import "../../styles/_variables";

.grid-container {
  display: grid;
  grid-template: 1fr / auto auto auto;

  .address {
    min-width: 9rem;
    font-size: 1rem;
    font-weight: bold;
  }

  .property-img {
    width: 9rem;
    @include qp-border-radius();
  }

  .property-size {
    display: block;

    &__item:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .grid-container {
    display: grid;
    grid-template: 1fr / fit-content(100%) 2fr;

    .property-img {
      margin-right: 1rem;
    }

    .responsive-table {
      grid-column: auto / span 2;
    }
  }
}

@media screen and (max-width: 767px) {
  .address {
    padding-left: 0.2rem;
  }

  .grid-container {
    display: grid;
    grid-auto-flow: dense;
    grid-template: 1fr / 1fr 2fr;

    .responsive-table {
      grid-column: auto / span 2;
    }
  }
}
