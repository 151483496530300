@import "../../SiteShared.scss";
// Small Modal Height issue fix

.semanticModal {
  top: unset !important;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
  overflow-y: auto;
  &-request {
    &-header {
      background: $accent-color !important;
    }
    &-footer {
      background: $accent-color-light !important;
    }
    &-submit {
      background: $accent-color !important;
      color: white !important;
    }
    &-close {
      background: $primary-color !important;
      color: $accent-color-light !important;
    }
  }
  .label {
    padding-right: 12px !important;
  }
}
.datetimeC {
  .rdtPicker {
    right: 0px !important;
  }
}
.ui.dropdown > .text {
  display: inline-block;
  transition: none;
  margin-top: 10px;
}

.maintenance-invite-modal{
  .ui.selection.active.dropdown .menu {
    max-height:200px;
    overflow-y:auto;
  }
}

#primaryLocationId,
#unitFormInput,
#priorityFormInput,
#categoryFormInput {
  border-color: $accent-color !important;
}
.button-class {
  background: $accent-color !important;
}

.maintenance-details {
  &-header {
    background: $primary-color !important;
    &-inner {
      color: $accent-color-light !important;
    }
  }
}

.fixed_header {
  width: 400;
  table-layout: fixed;
  border-collapse: collapse;
}

.fixed_header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 60vh;
}

// .fixed_header thead tr {
//  display: block;
// }

.fixed_header th,
.fixed_header td {
  padding: 5px;
  text-align: left;
  min-width: 100%;
  // max-width: 100%;
}

// maintenance css
.maintenance-top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  .left-wrap {
    display: flex;
    align-items: center;
    h2 {
      font-size: 20px;
      font-weight: 800 !important;
      margin-top: 0px;
      color: #171a1d;
      font-family: "Be Vietnam", sans-serif !important;
    }
    .back-btn {
      width: 45px;
      height: 45px;
      background-color: rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 3px;
      margin-right: 15px;
    }
  }
  .right-wrap {
    button {
      width: 220px;
      height: 45px;
      border-radius: 3px;
      background-color: #5e6267;
      color: #fff;
      font-size: 13px;
      font-weight: 800;
      text-transform: uppercase;
      &:hover {
        background-color: #5e6267;
        color: #fff;
      }
    }
  }
  .header-rw {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .left-head {
      .page-back {
        width: 45px;
        height: 45px;
        padding: 10px;
        border-radius: 3px;
        background-color: rgba(0, 0, 0, 0.05);
        margin-right: 15px;
        cursor: pointer;
      }
    }
    .right-top-head {
      input {
        border-radius: 3px;
        box-shadow: 0 11px 14px -10px #e5eced;
        background-color: #fff;
        border: 0;
        height: 45px;
        margin-right: 20px;
        font-size: 13px;
        font-weight: 400;
        width: 230px;
        box-sizing: border-box;
        color: rgba(23, 26, 29, 0.7);
        background-color: #0000000d !important;
        box-shadow: none !important;
      }
    }
    .header {
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 0;
      text-align: left !important;
      color: #171a1d;
      font-family: "Be Vietnam", sans-serif !important;
    }
    p {
      font-size: 13px;
      font-weight: 400;
      color: #5e6267;
    }
  }
}
.notification-wrap {
  .notification-row {
    display: flex;
    padding: 25px 20px;
    background-color: #fff;
    border-bottom: 1px solid #5e62671a;
    .icon-block {
      min-width: 35px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: rgba(59, 28, 90, 0.10196078431372549);
      padding: 5px;
      margin-top: 4px;
    }
    .notification-text {
      font-size: 13px;
      line-height: 1.69;
      color: #171a1d;
    }
    .notification-time {
      font-size: 12px;
      color: #5e6267;
    }
  }
}
.notification-btn {
  border-radius: 3px;
  background-color: #0000000d;
  padding: 7px;
  min-width: 35px;
  width: 35px;
  height: 35px;
  margin: 0 10px;
  cursor: pointer;
  // &:before {
  //   content: "";
  //   width: 9px;
  //   height: 9px;
  //   border-radius: 50%;
  //   background-color: #ef2c2c;
  //   position: absolute;
  //   top: -4px;
  //   right: -4px;
  //   border: 1px solid #fff;
  // }
}
.maintenance-details {
  .ui.card {
    border-radius: 3px;
    box-shadow: 0 11px 14px -10px #e5eced;
    border: 0px;
    margin-bottom: 40px;
    width: 100%;
    font-family: "Be Vietnam", sans-serif !important;
  }
  .sub-header {
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px !important;
    span {
      font-size: 13px;
      font-weight: 500;
      color: #11ae7d;
      padding: 0px 20px;
    }
    .header {
      background-color: #f4f4f4;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 17px 21px;
      font-size: 12px;
      font-weight: bold !important;
      text-transform: uppercase;
      font-family: "Be Vietnam", sans-serif !important;
      color: #171a1d !important;
      border-radius: 3px 3px 0px 0px;
      margin-bottom: 0px;
    }
  }
  .item {
    margin-bottom: 30px;
    .description {
      font-size: 14px;
      font-weight: normal;
      color: #5e6267;
      margin-bottom: 5px;
    }
    .header {
      font-size: 14px;
      font-weight: normal !important;
      line-height: 25px;
      color: #181b20 !important;
    }
  }
  .customer-info-wrap {
    width: 100%;
    .content {
      border-top: 0px !important;
      font-family: "Be Vietnam", sans-serif !important;

      .description-wrapper {
        margin: 0px 0px 30px 0px;
        flex-direction: column;
        h2 {
          display: block;
          margin: 0px;
          padding: 0px;
          font-size: 14px;
          color: #5e6267;
        }
        .ui.segment {
          height: auto !important;
          border: 0px;
          padding: 0px;
          box-shadow: unset;
          margin: 0px;
          line-height: 25px;
          font-size: 14px;
          color: #181b20;
        }
      }
    }
  }
  .maintenance-info-wrap {
    .content {
      border: 0px !important;
    }
  }
  .save-tech-wrap {
    padding: 20px 30px !important;
    background-color: rgba(0, 0, 0, 0.05);
    flex-direction: column !important;
    margin: 0px !important;
    h4 {
      margin: 0px;
      font-size: 14px;
      font-weight: 800 !important;
      color: #171a1d;
      font-family: "Be Vietnam", sans-serif !important;
      padding: 0px;
    }
    h6 {
      padding: 0px;
      color: #5e6267;
      font-size: 12px;
      font-weight: normal;
      margin-bottom: 20px;
      font-family: "Be Vietnam", sans-serif !important;
    }
    .save-tech-btn-wrap {
      padding: 0px;
      display: flex;
      align-items: center;
      .dropdown {
        border-radius: 3px;
        box-shadow: 0 11px 14px -10px #e5eced;
        background-color: #fff;
        border: 0px;
        width: calc(100% - 225px);
        margin-right: 20px;
        height: 50px;
        input {
          height: 100%;
        }
        .text {
          top: 5px;
        }
        .dropdown.icon {
          top: 18px;
        }
      }
      button {
        border-radius: 3px;
        background-image: linear-gradient(103deg, #3b1c5a, #374db1 162%);
        width: 200px;
        color: #fff;
        height: 50px;
      }
    }
  }
  .set-times-wrap {
    padding: 20px 30px !important;
    background-color: rgba(0, 0, 0, 0.05);
    flex-direction: column !important;
    margin: 20px 0px !important;
    h4 {
      margin: 0px;
      font-size: 14px;
      font-weight: 800 !important;
      color: #171a1d;
      font-family: "Be Vietnam", sans-serif !important;
      padding: 0px;
    }
    h6 {
      padding: 0px;
      color: #5e6267;
      font-size: 12px;
      font-weight: normal;
      margin-bottom: 0px;
      font-family: "Be Vietnam", sans-serif !important;
    }
    .ui.grid {
      width: 100% !important;
      margin: 0px;
      padding: 0px !important;
      .column {
        padding: 0px;
        &:first-child {
          .datetime-col {
            margin-left: -10px;
          }
        }
        &:last-child {
          .datetime-col {
            margin-right: -10px;
          }
        }
        .rdt {
          margin: 0px 10px;
        }
        h3 {
          height: 40px;
          background-color: rgba(0, 0, 0, 0.05);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 11px;
          font-weight: 500 !important;
          color: #5e6267;
          text-transform: uppercase;
          font-family: "Be Vietnam", sans-serif !important;
        }
      }
      .datetime-col {
        input {
          border-radius: 3px;
          box-shadow: 0 11px 14px -10px #e5eced;
          height: 50px;
          box-sizing: border-box;
          font-size: 14px;
          font-weight: 500;
          color: #171a1d;
          font-family: "Be Vietnam", sans-serif !important;
          width: 100%;
        }
      }
    }
    button {
      width: 250px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto !important;
      border-radius: 3px;
      background-image: linear-gradient(101deg, #3b1c5a, #374db1 163%);
      color: #ffff;
      font-size: 13px;
      font-weight: 800;
      text-transform: uppercase;
      font-family: "Be Vietnam", sans-serif !important;
    }
  }
  .staff-notes-wrapper {
    .header {
      padding: 15px 20px 0px 15px;
      text-align: left !important;
      font-size: 13px;
      font-weight: 500 !important;
      color: #696d72;
      .header {
        padding: 0px;
      }
    }
    .content {
      border: 0px !important;
      display: flex;
      .ui.form {
        width: calc(100% - 50px);
        padding-right: 35px;
        textarea {
          border: 0px;
          resize: none;
          padding: 0px;
          color: #171a1d;
          font-size: 14px;
          line-height: 25px;
          font-weight: 500;
        }
      }
      .button {
        width: 45px;
        height: 45px;
        background-image: linear-gradient(133deg, #3b1c5a, #374db1 158%);
        border-radius: 50%;
        display: flex;
        align-items: center;
        padding: 0px;
        justify-content: center;
      }
    }
  }
  .log-wrap {
    .sub-header {
      margin-bottom: 0px !important;
    }
    .event {
      .content {
        display: flex !important;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 20px !important;
        margin-bottom: 10px;

        .left-col {
          .summary {
            color: #5e6267;
            font-size: 13px;
            font-weight: 500;
          }
          .text {
            font-size: 13px !important;
            font-weight: 500;
            margin-top: 0px;
            color: #171a1d;
          }
        }
        .date {
          font-size: 12px !important;
          font-weight: normal !important;
          color: #5e6267 !important;
        }
      }
      &:last-child {
        .content {
          border-bottom: 0px;
        }
      }
    }
  }
}
.ok-btn {
  background-image: linear-gradient(276deg, #7127c4, #4c158a) !important;
}


.tour-tag {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 18px;
  width: 81px;
  height: 24px;
  background: rgba(59, 28, 90, 0.05);
  border-radius: 100px;
  flex: none;
  order: 0;
  flex-grow: 0;

  .tour-tagText {
    width: 57px;
    height: 8px;
    font-family: "Be Vietnam";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    leading-trim: both;
    text-edge: cap;
    display: flex;
    align-items: center;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}