@import "../../../styles/_variables";
@import "../../../SiteShared.scss";

.new-conversation-modal {
  .content {
    padding: 0 !important;
  }
}

.archive-chat-modal-content {
  padding: 50px;

  .title {
    font-family: "BeVietnam", sans-serif;
    font-size: 22px;
    font-weight: 700;
    color: #171a1d;
  }

  .description {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    color: $gray-dark;
  }

  .buttons {
    margin-top: 30px;
    display: flex;

    .button {
      flex: 1;
      height: 45px;
      color: #fff;
      margin-right: 30px;
    }

    .dismiss {
      background-color: $gray-dark;
    }
    .danger {
      background-color: #ef2c2c;
    }
  }
}

@include for-phone {
  .archive-chat-modal-content {
    padding: 10px;

    .buttons {
      .button {
        height: auto;
      }

      .danger {
        margin-right: 0;
      }
    }
  }
}
