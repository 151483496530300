@import "./_variables";
.btn {
  width: auto;
  height: 50px;
  line-height: 50px;
  border-radius: 4px;
  font-size: 16px;
  padding: 0 15px;
  text-transform: none;
  &.btn-font-sm {
    font-size: 14px;
  }
  &.button-sm {
    min-width: 110px;
  }
  &.btn-primary {
    background: $primary-color !important;
    color: $white !important;
  }
  &.btn-secondary {
    background: $secondary-color !important;
    color: $primary-color !important;
  }
  &.btn-waiting {
    background-color: $orange-fade;
    color: $orange !important;
  }
  &.btn-application {
    background-color: $secondary-color;
    color: $black !important;
  }
  &.btn-dark {
    background-color: $gray-dark;
    color: $white !important;
  }
  &.btn-grey {
    background-color: #5e6267 !important;
    color: #fff !important;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 800;
    height: 45px;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
  }
}

.btn-med {
  height: 45px !important;
  min-width: 120px;
}

.btn-gradient {
  background-image: linear-gradient(102deg, #3b1c5a, #374db1 162%) !important;
  color: #fff !important;
  text-transform: uppercase !important;
  display: flex !important;
  align-items: center;
  font-size: 13px !important;
  font-family: "Be Vietnam" !important;
  text-align: center;
  justify-content: center;
  img {
    margin-right: 5px;
  }
}

.btn-primary-light {
  border-radius: 3px !important;
  background-color: #3b1c5a1a !important;
  font-size: 13px !important;
  font-weight: 800 !important;
  color: #3b1c5a !important;
  text-transform: uppercase !important;
}
