@import "../../../SiteShared.scss";

.min-750 {
  min-width: 750px;
}
.card-scroll-y {
  overflow-y: auto;
}

.table-header {
  display: flex;
  flex-direction: row;

  .table-header-label {
    display: flex;
    flex: 1;
    font-weight: 600;
    font-family: "Be Vietnam", sans-serif !important;

    &.x2 {
      flex: 2;
    }
  }
}

.table-col-row {
  display: flex;
  flex-direction: row;
  
  .table-col {
    display: flex;
    flex: 1;
    min-height: 40px;
    align-items: center;
    word-break: break-all;
    min-width:40px;
    &.x2 {
      flex: 2;
    }
  }
}

@include not-desktop {
  .profile-subtitle {
    flex-direction: column;
  }
}
