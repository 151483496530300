.upload-photo-container {
  margin-top: 30px;
  width: 100%;
  height: 135px;
  margin: 30px 0;
  border-radius: 3px;
  border: dashed 2px rgba(9, 71, 66, 0.2);
  background-color: rgba(23, 26, 29, 0.03);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  input {
    position: absolute;
    width: 150%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  .inner-section {
    .normal-text {
      font-size: 14px;
      color: rgba(#4c158a, 0.6);
      margin-top: 20px;
    }

    .hint {
      font-size: 12px;
      font-style: italic;
      color: #5e6267;
    }
  }
}
.preview-box {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -9px 30px;


  .img-box {
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 3px;
    position: relative;
    margin: 0 9px 18px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .overlay-box {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(#171a1d, 0.4);
    top: 0;
  }
  .white-delete-icon {
    position: absolute;
    top: 4px;
    right: 4px;
  }
}
