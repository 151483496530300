@import "../../styles/_variables.scss";

.create-event-modal {
  .modal-content {
    overflow-y: auto;
    max-height: calc(100vh - 49px);

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .modal-title {
    display: inline;
    font-family: "BeVietnam", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: $black;
    text-align: left !important;
    margin: 30px !important;
    position: relative;

    i {
      position: absolute;
      right: 0;
      cursor: pointer;
    }
  }
  .modal-body {
    padding: 0 0 50px 0 !important;
    background-color: rgb(248, 248, 248);
  }
}

.event-create-form {
  background-color: rgb(248, 248, 248);
  font-family: "BeVietnam", sans-serif;
  padding: 0 30px;

  .form-row {
    background-color: rgb(255, 255, 255);
    padding: 15px;
    margin: 15px 0;

    label {
      font-family: "BeVietnam", sans-serif;
      font-size: 12px;
      font-weight: 500;
      color: $gray-dark;
      display: block;
      width: 100%;
    }

    input,
    .input {
      font-family: "BeVietnam", sans-serif;
      color: $black;
      box-shadow: none !important;
      font-size: 14px;
      font-weight: 500;
      height: 30px;
      padding: 0;
      margin: 0;
      border: 0 !important;
      background-color: rgb(255, 255, 255);
      width: 100%;
    }

    textarea {
      border: 0;
      font-family: "BeVietnam", sans-serif;
      color: $black;
      box-shadow: none !important;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .pick-date,
  .pick-start-time,
  .pick-end-time {
    position: absolute;
    z-index: 100000;
  }

  .tags {
    width: 100%;
    margin-bottom: 10px;

    div {
      display: inline-block;
      margin: 3px;
    }
  }

  button {
    cursor: pointer;
    border: 0;
    background-color: $primary-color;
    text-align: center;
    color: $white;
    height: 50px;
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    font-family: "BeVietnam", sans-serif;
    margin-top: 15px;
  }
}