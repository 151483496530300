@import "./_variables";
/* points cards */

.point-col {
  width: auto;
  p {
    width: 100%;
    color: $black;
    font-size: 12px;
  }
  h2 {
    color: $black;
    font-size: 30px;
    margin: 0;
    line-height: 30px;
  }
  span {
    width: 15px;
    height: 15px;
    display: inline-block;
    background: $primary-color;
    border-radius: 50%;
    text-align: center;
    line-height: 15px;
    color: $white;
    font-size: 10px;
    margin-left: 3px;
    position: relative;
    top: -7px;
  }
}
.card {
  height: auto !important;
  .card-title {
    font-size: 16px;
  }
  .card-title-small {
    margin-top: 26px;
    font-size: 13px;
    color: $theme-text-color;
    strong {
      font-family: $bold-font;
    }
  }
  .card-image-box {
    height: 197px;
    position: relative;
    .card-img-top {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &.property-card {
    .property-size {
      display: flex;
      .item {
        display: inline-flex;
        align-items: center;
        img {
          max-height: 14px;
          margin-right: 8px;
        }
        span {
          font-size: 15px;
        }
        &:after {
          content: "|";
          margin: 0 10px;
          color: rgba(2, 30, 64, 0.2);
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
  &.horizontal-card {
    .card-body {
      display: flex;
      padding: 0;
      .card-image {
        width: 200px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .property-details {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 60%;
      }
      .card-details {
        width: calc(100% - 160px);
        padding: 15px;
        .date-applied-wrapper {
          padding-top: 20px;
          width: 40%;
          text-align: right;
          flex: none;
          button {
            margin: 0 0 15px 0;
          }
          p {
            font-size: 12px;
          }
        }
        .property-size {
          padding: 10px 0;
        }
      }
    }
  }
  &.offer-card {
    .offer-items {
      padding: 0 10px;
      flex-grow: 1;
      .item {
        width: calc(100% / 3);
        padding: 10px;
        border-right: 1px solid $gray-fade;
        h3 {
          font-size: 20px;
          margin: 0;
          line-height: 25px;
        }
        p {
          font-size: 12px;
          margin: 0;
        }
      }
    }
    .gift-icon {
      flex: none;
    }
    .arrow-icon {
      flex: none;
      cursor: pointer;
    }
  }
}
.my-fav-property {
  .card {
    .card-title {
      &.title-bottom {
        position: absolute;
        top: -36px;
        right: 0;
        background: $theme-text-color;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: $white;
        width: auto;
        padding: 0 20px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }
  .property-details {
    padding-top: 20px;
  }
}
.btn-span {
  color: $primary-color;
}

// New CSS
.theme-card {
  &.bordered{
    box-shadow: 0 11px 14px -10px #e5eced;
    border: dashed 1px #3b1c5a;
  }
  &.card {
    box-shadow: 0 11px 14px -10px #e5eced;
    background-color: #fff;
    margin-bottom: 30px;
    .card-header {
      padding: 17px 20px 16px;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      background-color: #f4f4f4;
      border: 0;
      .card-header-title {
        font-size: 12px;
        font-weight: bold;
        color: #171a1d;
        text-transform: uppercase;
      }
      .card-profile-header {
        padding: 13px 20px;
      }
      &.unit-header{
        background-color: #eeecf0;
        padding: 24px 20px;
        text-align: center;
        cursor: pointer;
        .card-header-title{
          font-size: 15px;
          font-weight: bold;
          color: #3b1c5a;
        }
      }
    }
    .card-body {
      padding: 20px;
      .card-list {
        .card-list-item {
          padding: 20px 0;
          border-bottom: 1px dashed #0000001a;
          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
          }
          &:first-child {
            padding-top: 0;
          }
          .list-title {
            font-size: 14px;
            font-weight: 600;
            color: #171a1d;
          }
          .list-text {
            font-size: 13px;
            font-weight: 500;
            color: #5e6267;
          }
          .list-text-dark {
            @extend .list-text;
            color: #171a1d;
          }
          .card-text-light {
            font-size: 12px;
            color: #5e6267;
          }
        }
      }
      .card-profile-body {
        padding: 10px 20px;
        &.lease-renew-body{
          padding-left: 30px;
          padding-right: 30px;
          input{
            max-height: 38px;
            height: auto;
          }
          .ui{
            &.input{
              width: 100%;
            }
            &.search{
              &.dropdown{
                width: 100%;
              }
            }
          }
        }
      }
      .card-row-notice{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px dashed #0000001a;
        padding: 20px 0;
        &:first-child{
          padding-top: 0;
        }
        &:last-child{
          border-bottom: 0;
          padding-bottom: 0;
        }
        .notice-title{
          font-size: 13px;
          font-weight: 500;
          color: #5e6267;
        }
        .notice-subtitle{
          font-size: 13px;
          font-weight: 500;
          color: #171a1d;
        }
        .notice-time{
          font-size: 12px;
          color: #5e6267;
          white-space: nowrap;
          margin-left: 30px;
        }
      }
      .card-row-services{
        margin: 0 -7.5px;
        display: flex;
        flex-wrap: wrap;
        .services-label{
          margin: 0 7.5px 15px;
          padding: 4px 15px 7px;
          // opacity: 0.05;
          border-radius: 15px;
          background-color: #5e62671d;
          font-size: 13px;
          font-weight: 500;
          color: #5e6267;
        }
      }
      .card-row-stats{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -16.5px;
        .stats-col{
          margin: 0 16.5px 10px;
          flex: 1 1 auto;
          .stats-label{
            font-size: 13px;
            margin-bottom: 5px;
            color: #5e6267;
          }
          .stats-value{
            font-size: 18px;
            font-weight: bold;
            color: #171a1d;
          }
        }
      }
      .maintenance-card{
        padding: 20px 20px 25px;
        border-radius: 3px;
        background-color: #5e63680d;
        margin-bottom: 15px;
        .maintenance-icon{
          min-width: 35px;
          width: 35px;
          height: 35px;
          margin: 5px 15px 12px 0;
          padding: 5px;
          border-radius: 3px;
          background-color: #b9babb33;
        }
        .maintenance-label{
          font-size: 16px;
          font-weight: 500;
          color: #171a1d;
        }
        .maintenance-time{
          font-size: 13px;
          font-weight: 500;
          color: #5e6267;
        }
        .maintenance-description{
          line-height: 1.86;
          color: #5e6267;
        }
        .points-card{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .maintenance-label{
            font-size: 14px;
          }
          .maintenance-time{
            font-weight: 400;
          }
          .points-label{
            font-size: 15px;
            font-weight: bold;
            display: flex;
            margin-left: 30px;
            &.success{
              color: #11ae7d;
            }
          }
        }
      }
      .points-header{
        padding: 7px 20px;
        background-color: #5e62670d;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .points-head-label{
          font-size: 11px;
          font-weight: bold;
          color: #5e6267;
          text-transform: uppercase;
          min-width: 60px;
        }
      }
      .payment-table-header{
        display: flex;
        padding: 12px 0;
        background-color: #f8f8f8;
        margin-bottom: 10px;
        .table-header-label{
          flex: 1 1 auto;
          font-size: 11px;
          font-weight: 500;
          color: #5e6267;
          padding: 0 20px;
          text-transform: uppercase;
          &:nth-child(1){
            width: 15%;
          }
          &:nth-child(2){
            width: 15%;
          }
          &:nth-child(3){
            width: 30%;
          }
          &:nth-child(4){
            width: 10%;
          }
          &:nth-child(5){
            width: 10%;
          }
        }
        .table-header-label-payment{
          flex: 1 1 auto;
          font-size: 11px;
          font-weight: 500;
          color: #5e6267;
          // padding: 0 40px;
          text-align:center;
          text-transform: uppercase;
          &:nth-child(1){
            width: 6%;
          }
          &:nth-child(2){
            width: 10%;
          }
          &:nth-child(3){
            width: 6%;
          }
          &:nth-child(4){
            width: 50%;
          }
          &:nth-child(5){
            width: 6%;
          }
          &:nth-child(6){
            width: 6%;
          }
          &:nth-child(7){
            width: 6%;
          }
          &:nth-child(8){
            width: 6%;
          }
        }
        .table-header-label-application{
          flex: 1 1 auto;
          font-size: 11px;
          font-weight: 500;
          color: #5e6267;
          padding: 0 20px;
          text-transform: uppercase;
          &:nth-child(1){
            width: 15%;
          }
          &:nth-child(2){
            width: 15%;
          }
          &:nth-child(3){
            width: 15%;
          }
          &:nth-child(4){
            width:15%;
          }
          &:nth-child(5){
            width: 15%;
          }
          &:nth-child(6){
            width: 15%;
          }
        }
        .table-header-label-tenant{
          flex: 1 1 auto;
          font-size: 11px;
          font-weight: 500;
          color: #5e6267;
          padding: 0 20px;
          text-transform: uppercase;
          &:nth-child(1){
            width: 20%;
          }
          &:nth-child(2){
            width: 15%;
          }
          &:nth-child(3){
            width: 30%;
          }
          &:nth-child(4){
            width: 10%;
          }
          &:nth-child(5){
            width: 10%;
          }
        }
        .table-header-label-payment{
          flex: 1 1 auto;
          font-size: 11px;
          font-weight: 500;
          color: #5e6267;
          // padding: 0 40px;
          text-align:center;
          text-transform: uppercase;
          &:nth-child(1){
            width: 6%;
          }
          &:nth-child(2){
            width: 10%;
          }
          &:nth-child(3){
            width: 6%;
          }
          &:nth-child(4){
            width: 50%;
          }
          &:nth-child(5){
            width: 6%;
          }
          &:nth-child(6){
            width: 6%;
          }
          &:nth-child(7){
            width: 6%;
          }
          &:nth-child(8){
            width: 6%;
          }
        }
      }
      &.lease-history-table{
        .table-header-label{
          &:nth-child(1){
            width: 20%;
          }
          &:nth-child(2){
            width: 20%;
          }
          &:nth-child(3){
            width: 30%;
          }
          &:nth-child(4){
            width: 10%;
          }
          &:nth-child(5){
            width: 10%;
          }
          &:nth-child(6){
            width: 10%;
          }
          
        }
        .payment-table-row{
          .table-col{
            width: 20% !important;
          }
        }
      }
      .payment-table-body{
        max-height:250px;
        overflow-y:auto;
        .payment-table-row{
          padding: 30px 0;
          background-color: #5e62670d;
          margin-bottom: 20px;
          display: flex;
          .table-col{
            flex: 1 1 auto;
            color: #5e6267;
            padding: 0 20px;
            .status{
              border-radius: 15px;
              background-color: #5e62670d;
              font-size: 11px;
              font-weight: bold;
              display: flex;
              align-items: center;
              justify-content: center;
              text-transform: uppercase;
              height: 25px;
              width: 80px;
              &.success{
                background-color: #11ae7d0d;
                color: #11ae7d;
              }
            }
            .payment-property-title{
              font-weight: 500;
              color: #171a1d;
            }
            .payment-property-subtitle{
              font-size: 13px;
            }
            &:nth-child(1){
              width: 20%;
            }
            &:nth-child(2){
              width: 20%;
            }
            &:nth-child(3){
              width: 30%;
            }
            &:nth-child(4){
              width: 10%;
            }
            &:nth-child(5){
              width: 10%;
            }
            &:nth-child(6){
              width: 10%;
            }
          }
        }
      }
    }
  }
}

.upload-document-wrap{
  .upload-document-col{
    border-radius: 3px;
    border: dashed 1px rgba(9, 71, 66, 0.2);
    background-color: rgba(23, 26, 29, 0.03);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    label{
      width: 100%;
      text-align: center;
      font-family: "Be Vietnam", sans-serif !important;
      padding: 40px 30px 27px;
      cursor: pointer;
    }
    img{
      margin-bottom: 10px;
    }
    .upload-title{
      font-size: 14px;
      color: #3b1c5a;
    }
    .upload-subtitle{
      font-size: 12px;
      color: #5e6267;
      font-style: italic;
    }
  }
  .documents-row{
    display: flex;
    flex-wrap: wrap;
    margin: 20px -10px;
    .documents-col{
      width: 70px;
      height: 70px;
      margin: 0 10px 20px;
      border-radius: 3px;
      position: relative;
      .icon-file{
        border-radius: 3px;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .delete-file{
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
      }
    }
  }
}

.renew-lease-header{
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0 11px 14px -10px #e5eced;
  background-color: #fff;
  margin-bottom: 30px;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7.5px;
  .services-list-item {
    font-size: 13px;
    font-weight: 500;
    color: #5e6267;
    border-radius: 15px;
    background-color: #5e62670d;
    padding: 4px 15px 7px;
    margin: 0 7.5px 15px;
  }
}
.resident-profile-icon {
  margin-right: 20px;
  padding: 18px 17px 18px 18px;
  border-radius: 3px;
  background-color: #e3e0e6;
}
.profile-title {
  font-size: 18px;
  font-weight: bold;
  color: #3b1c5a;
}
.profile-subtitle {
  font-size: 13px;
  font-weight: 500;
  color: #5e6267;
  margin-top: 1px;
  display: flex;
  align-items: center;
  a{
    color: #5e6267 !important;
  }
}
.resident-account-status {
  padding: 7px 11px 6px;
  border-radius: 15px;
  background-color: #dfede9;
  font-size: 12px;
  font-weight: bold;
  color: #11ae7d;
  text-transform: uppercase;
  margin-left: 20px;
}
.profile-basic {
  margin-bottom: 23px;
}
.profile-label {
  font-size: 14px;
  color: #5e6267;
  margin-bottom: 5px;
}
.profile-label-value {
  font-size: 14px;
  line-height: 1.79;
  color: #181b20;
}
.profile-label-row {
  display: flex;
  align-items: center;
  margin: 9px 0 30px;
}
.profile-label-title {
  font-size: 16px;
  font-weight: bold;
  color: #181b20;
}
.profile-label-subtitle {
  font-size: 13px;
  color: #5e6267;
  margin-left: 5px;
}
.profile-label-line {
  height: 1px;
  border-bottom: 1px dashed #0000001a;
  flex: 1 1 auto;
  margin-left: 20px;
  margin-top: 4px;
}

.dot-separator{
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 10px 10px 5px;
  opacity: 0.3;
  background-color: #5e6267;
}
.scroll-content{
  max-height: 420px;
  overflow-x: auto;
}