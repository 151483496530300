.tour-topSpace {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 15px;
    // width: 235px;
    height: 45px;
    left: 320px;
    top: 155px;
    justify-content: space-between;
    .tour-topLeft {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 15px;
  
      .tour-backButton {
        width: 45px;
        height: 45px;
        flex: none;
        order: 0;
        flex-grow: 0;
        background: #ebebeb;
  
        .tour-backArrow {
          // position: absolute;
          padding: 10px;
          transform: rotate(-0deg);
        }
      }
      .tour-title {
        // width: 175px;
        height: 20px;
        font-family: "Be Vietnam";
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 20px;
        color: #171a1d;
        flex: none;
        order: 1;
        flex-grow: 0;
      }
    }
    .tour-topRight {
      .tours-typeDropdown {
        width: 130px !important;
        height: 35px;
        margin-right: 13px;
        .text {
          width: 50px;
          height: 18px;
          left: calc(50% - 50px / 2 - 25px);
          top: calc(50% - 18px / 2 + 0.5px);
          font-family: "Be Vietnam";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 0px;
          color: #5e6267;
        }
      }
      .tour-addTourButton {
        width: 96px;
        height: 39px;
        background: linear-gradient(132.99deg, #3b1c5a 0%, #374db1 157.68%);
        border-radius: 4px;
        font-family: "Be Vietnam";
        font-style: normal;
        font-weight: 800;
        font-size: 13px;
        line-height: 19px;
        color: #ffffff;
        leading-trim: both;
        text-edge: cap;
        text-transform: uppercase;
      }
    }
  }
  
  .tour-rows {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    width: 100%;
    height: 510px;
    left: 320px;
    top: 234px;
    .tour-outerCard {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px;
      width: 100%;
      height: 86px;
      background: #ffffff;
      box-shadow: 0px 11px 14px -10px #e5eced;
      flex: none;
      order: 0;
      flex-grow: 0;
      .tour-cardTextLeft {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        // width: 734px;
        height: 46px;
        flex: none;
        order: 0;
        flex-grow: 1;
        .tour-cardTitle {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 8px;
          width: 100%;
          height: 20px;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          font-family: "Be Vietnam";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
        .tour-cardDescription {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          gap: 12px;
          width: 100%;
          height: 18px;
          flex: none;
          order: 1;
          flex-grow: 0;
          .tour-date {
            flex: none;
            order: 0;
            flex-grow: 0;
            font-family: "Be Vietnam";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #5e6267;
          }
          .tour-length {
            flex: none;
            order: 1;
            flex-grow: 0;
            font-family: "Be Vietnam";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #5e6267;
          }
          .tour-type {
            flex: none;
            order: 2;
            flex-grow: 0;
            font-family: "Be Vietnam";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
  
            color: #5e6267;
          }
  
          .tour-cardImg {
            padding-right: 5px;
          }
        }
      }
      .tour-cardTextRight {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;
        width: 116px;
        height: 25px;
        flex: none;
        order: 1;
        flex-grow: 0;
        .tour-dots:hover {
          cursor: pointer !important;
          .tour-cardImg {
            cursor: pointer !important;
          }
        }
        .tour-tag {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 8px 12px;
          width: 81px;
          height: 24px;
          background: rgba(59, 28, 90, 0.05);
          border-radius: 100px;
          flex: none;
          order: 0;
          flex-grow: 0;
  
          .tour-tagText {
            width: 57px;
            height: 8px;
            font-family: "Be Vietnam";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            leading-trim: both;
            text-edge: cap;
            display: flex;
            align-items: center;
            flex: none;
            order: 0;
            flex-grow: 0;
          }
        }
      }
    }
  }
  
  .tour-addTypeModal {
    position: absolute;
    width: 500px;
    height: 797px;
    left: 50%;
  
    transform: translateX(-50%);
    top: -50px;
    background: #f8f8f8;
    border-radius: 6px;
    .modal-tourContent {
      width: 500px;
      height: 797px;
      overflow: hidden;
      .modal-tourHeader {
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 5px;
        .modal-tourTitle {
          font-family: "Be Vietnam";
          font-style: normal;
          font-weight: 800;
          font-size: 22px;
          line-height: 32px;
  
          color: #171a1d;
        }
      }
      .modal-body {
        padding-left: 50px;
        padding-right: 50px;
        .modal-tourButton {
          button:disabled {
            background-color: grey !important;
            border-radius: 3px;
            height: 45px;
            width: 400px;
            left: 0px;
            top: 0px;
            border-radius: 3px;
  
            span {
              height: 45px;
              left: 6.67%;
              right: 6.67%;
              top: calc(50% - 45px / 2);
  
              font-family: "Be Vietnam";
              font-style: normal;
              font-weight: 800;
              font-size: 13px;
              line-height: 19px;
  
              align-items: center;
              text-align: center;
              text-transform: uppercase;
  
              color: #ffffff;
            }
          }
          button:enabled {
            background: linear-gradient(132.99deg, #3b1c5a 0%, #374db1 157.68%);
            border-radius: 3px;
            height: 45px;
            width: 400px;
            left: 0px;
            top: 0px;
            border-radius: 3px;
  
            span {
              height: 45px;
              left: 6.67%;
              right: 6.67%;
              top: calc(50% - 45px / 2);
  
              font-family: "Be Vietnam";
              font-style: normal;
              font-weight: 800;
              font-size: 13px;
              line-height: 19px;
  
              align-items: center;
              text-align: center;
              text-transform: uppercase;
  
              color: #ffffff;
            }
          }
  
          // position: absolute;
        }
        .modal-tourInputs {
          padding-top: 20px;
          .has-float-label {
            background-color: transparent;
            box-shadow: none;
            height: auto;
            .radio-input {
              padding: 10px;
              display: flex;
              justify-content: flex-end;
              .slider {
                margin-right: 25px;
                margin-top: 10px;
              }
            }
            .form-control {
              background-color: #fff !important;
              border-radius: 3px;
              box-shadow: 0 11px 14px -10px #e5eced !important;
              border: 0 !important;
              padding: 15px 20px 20px !important;
              height: 75px;
            }
            select.form-control {
              // & + label{
              //   top: 25px !important;
              //   font-size: 15px;
              // }
            }
            textarea.form-control {
              padding-top: 28px !important;
              height: auto;
            }
            .form-control:placeholder-shown:not(:focus) + * {
              top: 25px;
            }
            label {
              top: 7px;
            }
            .self-Booking {
              top: 25px;
            }
          }
          label {
            font-family: "Be Vietnam";
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
            /* identical to box height */
  
            color: #5e6267;
          }
          input {
            width: 400px;
            height: 75px;
            background: #ffffff;
            box-shadow: 0px 11px 14px -10px #e5eced;
            border-radius: 3px;
            // transform: matrix(1, 0, 0, -1, 0, 0);
          }
        }
      }
    }
  }
  
  .DeleteTourTypesModal {
    height: 273px !important;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    .modal-dialog-centered-tour {
      width: 100%;
      height: 100%;
      .modal-header {
        padding: 50px;
  
        .modal-title {
          // position: absolute;
          width: 110px;
          height: 29px;
  
          font-family: "Be Vietnam";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 29px;
          // display: flex;
          // align-items: center;
  
          color: #ef2c2c !important;
        }
        .modal-sutitle {
          // position: absolute;
          height: 48px;
          left: 10%;
  
          top: calc(50% - 48px / 2 - 28.5px);
  
          font-family: "Be Vietnam";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          /* or 150% */
  
          color: #5e6267;
        }
        .modal-large-btn-yes {
          height: 50px;
  width: 250px;
  left: 0px;
  top: 0px;
  border-radius: 3px;
  background: #EF2C2C;
  
        }
        .modal-large-btn-cancel {
          height: 50px;
  width: 135px;
  left: 0px;
  top: 0px;
  border-radius: 3px;
  background: #5E6267;
  
        }
      }
    }
  }
  
  
  .no-conversation {
    display: flex;
    flex: 1;
    // min-height: $max-height-of-chat-room-container;
    height: 80vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    img {
      margin: 0 auto;
    }
  
    .mw-container {
      max-width: 380px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .title {
      margin-top: 25px;
      // color: $primary-color;
      font-size: 18px;
      font-weight: 800;
      text-align: center;
    }
    .description {
      margin-top: 15px;
      line-height: 22px;
      font-size: 13px;
      // color: $gray-dark;
      text-align: center;
      font-weight: 500;
  
      b {
        // color: $black;
      }
    }
  }