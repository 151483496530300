@import "../../SiteShared.scss";

.push-notification-container {
  padding: 0 15px;

  .header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  .left-container {
    .head {
      text-align: left;
      font-size: 20px;
      font-weight: 800;
      color: #171a1d;
    }

    p {
      font-size: 13px;
      color: #5e6267;
    }
  }

  .push-notification-form {
    margin-top: 50px;
    margin-bottom: 100px;

    .field {
      margin-top: 15px;
      label {
        text-align: left;
      }
    }

    button {
      background-color: $accent-color !important;
      color: #fff !important;
      text-transform: uppercase;

      :hover {
        opacity: 0.9;
      }
    }
  }
}
