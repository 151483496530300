@import "./_variables";
@import "./quillpro/quillpro-variables";

@media screen and (max-width: 767px) {
  .responsive-table {
    border: 0;

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    table tr {
      border-top: solid $qp-color-darkblue 2px;
      // background-image: linear-gradient(to right, $qp-color-primary, $qp-color-secondary);
      // border-bottom: solid $qp-color-darkblue 2px;
      display: block;
      margin-bottom: 0.625em;
    }

    table tr:first-child {
      border-top: none;
    }
    table tr:last-child {
      border-bottom: none;
    }

    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      text-align: right;
      padding-top: 3px;
      padding-bottom: 3px;
    }
    table td::before {
      content: attr(data-target);
      color: #021e40;
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
