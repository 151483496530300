@import "../../../styles/_variables";
@import "../../../SiteShared.scss";

.text-wrapper {
  border-top: 2px solid
    rgba(red($gray-dark), green($gray-dark), blue($gray-dark), 0.05);
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: row;
  height: 77px;
  padding: 15px;

  .text-input {
    margin-top: 15px;
    height: 20px;
    color: #171a1d;
    font-size: 13px;
    font-weight: 400;
    border: 0 !important;
    box-shadow: none !important;
    border-radius: 0;
  }

  .text-wrapper-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .send-button {
    flex-shrink: 0;
    text-align: center;
    cursor: pointer;
    margin-right: 30px;
    box-sizing: content-box;
    width: 45px;
    height: 45px;
    border-radius: 23px;
    background: linear-gradient(to bottom, $primary-color, #374db1);

    .send-icon {
      color: #fff;
      width: 15px;
      height: 15px;
      margin: 13px auto 0;
    }
  }

  .attachment-button {
    flex-shrink: 0;
    text-align: center;
    margin: 0 10px;
    cursor: pointer;

    width: 35px;
    height: 35px;
    border-radius: 18px;
    background-color: rgba(
      red($gray-dark),
      green($gray-dark),
      blue($gray-dark),
      0.1
    );

    .attachment-icon {
      color: $gray-dark;
      width: 15px;
      height: 15px;
      margin: 10px auto 0;
    }
  }
}

@include for-phone {
  .text-wrapper {
    flex-direction: column;
    height: 60px;
    display: flex;
    flex-direction: row;

    .text-input {
      flex: 1;
    }

    .text-wrapper-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .send-button {
        margin-right: 0 !important;
      }
    }
  }
}
