@import "../../styles/quillpro/quillpro-variables";
@import "../../styles/_variables";
@import "../../SiteShared.scss";

.properties-container {
  .nav {
    background: none;
    display: inline;
    color: black !important;
    padding-left: 0px !important;
  }
  .nav:hover {
    background: none !important;
  }
  .not-allowed {
    opacity: 0.5 !important;
    pointer-events: none;
    cursor: not-allowed;
  }

  .alert {
    margin: 0.9375rem;
  }

  .property-img {
    height: 10.5rem;
    width: 100%;
    border-bottom-left-radius: 0.4673rem !important;
    border-top-left-radius: 0.4673rem !important;
  }

  a {
    width: 40px;
    padding: 0;
    background: $qp-color-primary;
    border-radius: $qp-border-radius-small;
    color: $qp-color-white !important;
  }

  .batch-icon-list {
    margin: 7px auto;
  }
  .notification-number {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    background: red;
    text-align: center;
    line-height: 20px;
    font-size: 10px;
    color: #ffffff;
    box-shadow: -16px -12px 46px 0px rgba(0, 0, 0, 0.4);
    position: absolute;
    // transform: translateX(60px);
    right: -10px;
    top: -10px;
  }

  .progress-bar {
    background-color: $qp-color-primary;
    color: red;
  }
}

.paybillsmodaloverview {
  width: 85% !important;
  overflow-y: hidden !important;
  margin-left: 3vw !important;
  // z-index: 99999999 !important;
  .billscontent {
    overflow-y: auto;

    .paymentsPane {
      margin-bottom: 110px !important;
      .ui.dropdown .menu {
        // background:red !important;
        z-index: 999999 !important;
        position: absolute !important;
        right: 0 !important;
        left: auto;
      }
    }
  }
}

.semanticModal {
  top: unset !important;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
  overflow-y: auto;
  &-request {
    &-header {
      background: $accent-color !important;
    }
    &-footer {
      background: $accent-color-light !important;
    }
    &-submit {
      background: $accent-color !important;
      color: white !important;
    }
    &-close {
      background: $primary-color !important;
      color: $accent-color-light !important;
    }
  }
  .label {
    padding-right: 12px !important;
  }
}

.semanticModal-attachModal {
  .content {
    max-height: 200px;
    overflow-y: auto;
    .card {
      .content {
        overflow: hidden;
      }
    }
  }
}

.overridelist {
  margin-left: auto !important;
  margin-right: 0 !important;
  .header {
    margin-left: auto !important;
    margin-right: 0 !important;
  }
  .description {
    margin-left: auto !important;
    margin-right: 0 !important;
  }
}

.card-holder {
  border-radius: 6px;
  box-shadow: 5px 11px 14px 10px #e5eced;
  background-color: #ffffff;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 15px;
  margin-top: 5px;
  background-image: url("/assets/img/card-bg.png");
  background-repeat: no-repeat;
  background-position: center right;
  position: relative;
  #number {
    text-align: left;
  }

  img {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  input {
    box-shadow: unset;
    border: 0px;
    border-bottom: 3px solid #e3e3e3;
    border-radius: 0px;
    margin: 0px 10px;
    text-align: center;
    padding-bottom: 0px;
    font-size: 15px;
    font-weight: normal;
    background-color: unset !important;
  }
  label {
    font-size: 11px;
    font-weight: normal;
    color: #5e6267;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-family: "Be Vietnam", sans-serif;
  }

  .footer-data {
    margin-top: 20px !important;
    display: flex !important;
    justify-content: space-between !important;
    .left-data,
    .right-data {
      width: 50% !important;
      .form-control-select {
        display: block !important;
        width: 100% !important;
        min-width: 100px !important;
        max-width: 100px !important;
        margin-right: 0px !important;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #f9f9fb;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }
    .left-data {
      input {
        width: 195px;
      }
    }
    .right-data {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      input {
        width: 35px;
      }
    }
    input {
      text-align: left;
      height: 20px;
      padding: 0px;
      margin: 0px;
    }
  }
  .form-group {
    border-radius: 3px;
    box-shadow: 0 11px 14px -10px #e5eced;
    background-color: #ffffff;
    // padding: 15px 20px;
    margin-bottom: 15px;
    height: 48px;
    input {
      width: 100%;
      margin: 0px;
      border: 0px;
      box-shadow: unset;
      padding: 0px;
      font-size: 14px;
      font-weight: 500;
      color: #171a1d;
    }
    label {
      font-size: 13px;
      font-weight: 500;
      font-family: "Be Vietnam", sans-serif;
      color: #5e6267;
      margin-bottom: 0px;
    }
    .selection.dropdown {
      border: 0px;
      width: 100%;
      padding: 10px 0px;
      .dropdown.icon {
        right: 0px;
      }
    }
  }
  .form-rws {
    display: flex;
    width: 100%;
    margin: 0px -10px;
    .form-group {
      width: 50% !important;
      margin: 0px 10px 20px;
    }
  }
}
.form-rws {
  display: flex;
  width: 100%;
  margin: 0px -10px;
  .form-group {
    width: 50% !important;
    margin: 0px 10px 20px;
  }
}

.settings-outerCard {
  // position: absolute;
  // margin-left:50px !important ;
  width: 100%;
  height: 80px;
  left: 320px;
  top: 334px;
  // position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  background: #ffffff;
  box-shadow: 0px 11px 14px -10px #e5eced;
  .settings-cardText {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 34.4px;
    .settings-cardArrow {
      float: right;
      width: 50px;
      margin-top: -20px !important;
      transform: rotate(-180deg);
    }
    .settings-cardTitle {
      width: 122px;
      height: 20px;
      left: 30px;
      top: 20px;

      font-family: "Be Vietnam";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;

      color: #171a1d;
    }
    .settings-cardDescription {
      width: 185px;
      height: 19px;
      left: 30px;
      top: 40px;

      font-family: "Be Vietnam";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      /* identical to box height */

      color: #5e6267;
    }
  }
}
.settings-outerCard:hover {
  cursor: pointer;
}