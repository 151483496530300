@import "../../../styles/_variables";
@import "../../../SiteShared.scss";

.room-list-tabs {
  display: flex;
  flex-direction: row;

  div {
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 1;

    align-items: center;
    justify-content: center;
    height: 45px;
    background-color: rgba(59, 28, 90, 0.05);
    cursor: pointer;

    &.mobile {
      display: none;
    }

    span {
      display: block;
      font-size: 13px;
      font-weight: 600;
      font-family: "BeVietnam", sans-serif;
      color: $gray-dark;
    }

    &.active {
      border-bottom: 2px solid $primary-color;
      span {
        color: $primary-color;
      }
    }
  }
}

@include for-phone {
  .room-list-tabs {
    overflow-x: auto;
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 0;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    div {
      &.item {
        width: 150px;
        flex-shrink: 0;
      }
    }

    &.toggle-open {
      div.item {
        display: flex;
      }
    }
  }
}
