@import "../../SiteShared.scss";

// .ui.stackable.cards {
//   &.row-1 {
//     margin-top: 0;
//   }
// }
// .lowPrior {
//   color:#6b66ff !important;
// }
// .medPrior {
//   color:#8567ba !important;
// }
// .highPrior {
//   color:#ff9821 !important;
// }

// .dashboard {
//   .ui.cards {
//     .card {
//       background-color: $dashboard-background-color;

//       .content {
//         padding: 0px;
//         .header.card-header {
//           font-weight: 500;
//           color: $dashboard-header-font-color;
//         }
//         a.header.card-header:hover {
//           color: $dashboard-header-font-color-hover;
//         }

//         h1 {
//           color: $accent-color;
//         }

//         h4 {
//           font-size: 0.9em;
//         }

//         .card-content-sub {
//           padding: 15px;
//           overflow-y: auto;
//           max-height: 560px;
//           min-height: 400px;
//         }
//       }

//       .card-icon {
//         float: right;
//         font-size: 1em;
//         line-height: 1em;
//       }

//       &.stats-chart {
//         .dropdown-unit-stats {
//           .text {
//             font-size: 1.28571429em;
//             font-weight: 500;
//             color: $dashboard-header-font-color;
//           }
//         }

//         .menu {
//           background-color: $menu-background-color-dropdown;
//           width: 100%;
//           max-height: 20em;

//           .text,
//           .message {
//             color: $menu-font-color-primary;
//           }
//         }
//       }

//       &.agenda {
//         .agenda-date {
//           position: absolute;
//           transform: rotate(270deg);
//           margin: 0;
//           padding: 0;
//           top: 40px;
//           left: -10px;
//         }
//       }

//       &.todo {
//         .todo-inputnew {
//           padding: 10px;
//         }
//         .todo-list {
//           padding: 10px;
//           max-height: 33rem;
//           overflow-y: auto;
//           overflow-x: hidden;
//         }
//       }
//     }
//   }
// }

.agenda-date-col {
  min-height: 50px;
}

.dashboard {
  display: block;
  
  .card-header {
    background-color: unset;
    border: 0px;
  }
  .slick-slider {
    margin-bottom: 30px;
    .slick-list {
      border-radius: 3px;
    }
    .slick-slide {
      height: 120px;
      div {
        height: 100%;
        position: relative;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
        h4 {
          position: absolute;
          font-size: 20px;
          font-weight: 800 !important;
          padding: 30px;
          color: #fff;
          max-width: 255px;
        }
        .close-icon {
          position: absolute;
          top: 10px;
          right: 10px;
          img {
            width: 25px;
            height: 25px;
            cursor: pointer;
          }
        }
      }
    }
    .slick-dots {
      display: block;
      bottom: 10px;
      background-color: rgba(23, 26, 29, 0.9);
      width: 70px;
      height: 16px;
      left: 50%;
      border-radius: 8px;
      li,
      button {
        width: 6px;
        height: 6px;
        margin: 0px 2px;
      }
      button {
        &::before {
          opacity: 1;
          color: rgba(255, 255, 255, 0.3);
          line-height: 0px;
          width: 6px;
          height: 6px;
        }
      }
      .slick-active {
        button {
          &::before {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }
  .cards {
    .ui.fluid {
      box-shadow: unset;
      & > .content {
        &.card-content {
          border-radius: 3px !important;
          box-shadow: 0 11px 14px -10px #e5eced;
          background-color: #ffffff;
          .card-header {
            font-size: 15px;
            font-weight: bold;
            color: #ffffff !important;
            text-transform: uppercase;
            border-radius: 6px 6px 0px 0px;
            padding: 15px;
          }
        }
      }

      &.dashboard-today {
        .card-header {
          background-color: #6b66ff;
        }
      }
      &.dashbord-maint {
        .card-header {
          background-color: #ff9821;
        }
      }
      &.dashboard-pipeline {
        .card-header {
          background-color: #24c7af;
        }
      }
      &.dashboard-residents {
        .card-header {
          background-color: #8567ba;
        }
      }
      &.dashboard-units {
        .card-header {
          background-color: #161a1d;
        }
      }
    }
    .ui.grid {
      display: block;
      padding: 0px 15px;
      .wide {
        width: 100% !important;
        box-shadow: unset !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        &:last-child {
          border-bottom: 0px;
        }
        h1 {
          color: #171a1d;
          font-size: 14px;
          font-weight: bold !important;
          margin-top: 0px;
        }
        h4 {
          font-size: 13px;
          font-weight: 500;
          color: #5e6267 !important;
          margin-bottom: 0px;
        }
      }
    }
  }

  // Responsive css
  @include mobile-tablet{
    .row-1{
      padding: 0 4px;
    }
    .ui.cards{
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

.filter-lease-label{
  margin-right: 20px;
  color: #808080;
  font-size: 13px;
  span{
    color: #2e2e2e;
    font-size: 14px;
  }
  @include mobile-tablet{
    margin-bottom: 15px;
    margin-right: 0;
    span{
      margin-left: 15px;
    }
  }
}

.list:hover {
  .remove {
    opacity: 1 !important;
    transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
    cursor: pointer;
  }
}
.remove {
  margin-top: 16px !important;
  padding-left: 15px;
  opacity: 0 !important;
  @include mobile-tablet{
    opacity: 1 !important;
  }
}
.lowP {
  border-left: 3px solid #8567ba;
  border-radius: 3px 0px 0px 3px;
  input {
    pointer-events: none;
  }
}
.medP {
  border-left: 3px solid #24c7af;
  border-radius: 3px 0px 0px 3px;
  input {
    pointer-events: none;
  }
}
.higP {
  border-left: 3px solid #ff9821;
  border-radius: 3px 0px 0px 3px;
  input {
    pointer-events: none;
  }
}
.menu .medP {
  .text {
    color: #24c7af !important;
  }
}
.menu .lowP {
  .text {
    color: #8567ba !important;
  }
}
.menu .higP {
  .text {
    color: #ff9821 !important;
  }
}
.low {
  .text {
    color: #8567ba !important;
  }
}
.med {
  .text {
    color: #24c7af !important;
  }
}
.hig {
  .text {
    color: #ff9821 !important;
  }
}
.upcoming-tasks-wrapper {
  .ui.card.todo {
    box-shadow: 0 11px 14px -10px #e5eced;
    background-color: #ffffff;
    margin: 30px 0px;
    .card-content {
      .card-header {
        background-color: unset;
        border: 0px;
        color: #171a1d !important;
        padding: 20px;
        font-weight: normal !important;
        .text {
          font-size: 15px;
          font-weight: bold !important;
        }
        i {
          font-size: 13px;
          font-weight: 600;
          margin-left: 7px;
        }
        .main-btn {
          background-image: linear-gradient(94deg, #3b1c5a, #374db1 163%);
          color: #ffffff;
          font-size: 11px;
          font-weight: bold;
          text-transform: uppercase;
          width: 120px;
          height: 30px;
          border-radius: 3px;
          float: right;
        }
      }
    }
    .content-todo {
      min-height: 156px !important;
      .todo-inputnew {
        padding: 0px;
        border-radius: 3px;
        box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        margin: 0px 20px;
        height: 45px;
        input {
          border: 0px;
          height: 45px;
          line-height: 45px;
          padding: 0px 20px;
        }
        button {
          background-color: unset;
          background-image: url(/assets/img/icons-send.svg);
          background-repeat: no-repeat;
          background-position: top 12px right 22px;
          margin: 0px;
        }
      }
      .todo-list {
        max-height: 300px;
        overflow-y: auto;
        padding: 20px;
        .list {
          margin-bottom: 15px;
          .card {
            flex-direction: row;
            background-color: #f7f7f7;
            @include mobile-tablet{
              flex-wrap: wrap;
            }
            img {
              width: 25px;
              margin-left: 15px;
              cursor: pointer;
            }
            .input {
              width: calc(100% - 260px);
              @include mobile-tablet{
                width: 100%;
                padding-right: 20px;
              }
              input {
                background-color: #f7f7f7;
                border: 0px;
                font-size: 13px;
                font-weight: 600;
                color: #171a1d;
              }
            }
            .created-date {
              color: #5e6267;
              align-items: center;
              display: flex;
              font-size: 13px;
              font-weight: 600;
              margin-left: auto;
              padding-right: 20px;
              white-space: nowrap;
              @include mobile-tablet{
                margin-left: 0;
                padding-left: 12px;
                padding-bottom: 10px;
              }
            }
          }
          &.completed {
            .card,
            input {
              background-color: unset !important;
            }
            input {
              color: rgba(23, 26, 29, 0.5) !important;
            }
            span {
              color: rgba(94, 98, 103, 0.5) !important;
            }
          }
        }
      }
    }
  }
}
.dashboard-cal {
  max-height: 350px !important;
  padding-left: 0 !important;
  @include mobile-tablet{
    max-height: fit-content !important;
    .main-calendar{
      &.ui.container{
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
  .main-calendar{
    padding-left: 6px !important;
  }
}
.dashboard-calendar-wrap {
  // margin-top: 40px;
  h4 {
    font-size: 18px;
    font-weight: normal;
    color: #3b1c5a;
  }
  .renter-dashboard-schedule {
    box-shadow: 0 11px 14px -10px #e5eced !important;
  }
}
.celled {
  &.grid {
    padding-right: 0px !important;
  }
}
.schedule-rw {
  position: relative !important;

  .schedule-list {
    position: relative !important;
    overflow-y: overlay;
    .list {
      max-height: 350px;
    }
  }
}
.event-detail {
  width: 300px;
  border-radius: 2px;
  box-shadow: 0 11px 14px -10px #e5eced;
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
  position: absolute;
  right: 250px;
  top: -100px;
  z-index: 9000;
  .header {
    border-bottom: 1px solid rgba(94, 98, 103, 0.1);
    padding: 20px;
    h6 {
      font-size: 15px;
      font-weight: 600;
      color: #171a1d;
      margin-bottom: 0px;
    }
    span {
      font-size: 13px;
      font-weight: normal;
      color: rgba(23, 26, 29, 0.7);
      margin-top: -4px;
      display: block;
    }
  }
  .content {
    .list-rw {
      padding: 15px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-bottom: 1px solid rgba(94, 98, 103, 0.1);
      &.time-rw {
        flex-direction: row;
        .list-col {
          display: flex;
          flex-direction: column;
          width: 50%;
        }
      }
      label {
        color: #5e6267;
        font-size: 12px;
        font-weight: normal;
        margin-bottom: 0px;
      }
      span {
        color: #171a1d;
        font-size: 13px;
        font-weight: normal;
        line-height: 21px;
      }
      h6 {
        font-size: 12px;
        font-weight: normal;
        color: #555555;
        margin-bottom: 0px;
      }
    }
  }
  .footer-wrap {
    display: flex;
    height: 95px;
    align-items: center;
    flex-direction: column;
    padding: 0px 20px;
    button {
      height: 45px;
      color: #fff;
      border-radius: 3px;
      font-size: 13px;
      font-weight: 800;
      text-transform: uppercase;
    }
    .edit-btn {
      width: 100px;
      background-color: #6d6d6d;
      margin-right: 10px;
    }
    .reschedule-btn {
      background-image: linear-gradient(147deg, #3b1c5a, #374db1 155%);
      width: 150px;
    }
  }
}

.stats-data-wrapper {
  margin-bottom: 30px !important;
  .card {
    box-shadow: 0 11px 14px -10px #e5eced !important;
    background-color: #ffffff !important;
    border: 0px !important;
    .card-header {
      padding: 20px;
      color: #171a1d !important;
      font-weight: normal !important;
      .text {
        color: #171a1d !important;
        font-size: 15px;
        font-weight: bold;
      }
      i {
        margin-left: 7px !important;
        &::before {
          content: "\f107" !important;
          color: #171a1d;
          font-family: "FontAwesome";
          font-weight: 600;
        }
      }
      .visible.menu {
        border-radius: 3px;
        box-shadow: 0 11px 14px -10px #e5eced;
        background-color: #ffffff;
        width: 210px;
        border: 1px solid #f5f5f5;
        .item {
          .text {
            color: #5e6267 !important;
            font-size: 13px;
            font-weight: normal;
          }
        }
      }
    }
    .content {
      canvas {
        margin-bottom: 30px;
      }
    }
  }
  .specials {
    .card-header {
      font-size: 15px !important;
      font-weight: bold !important;
    }
    .ui.message {
      border: 1px dashed #cac5c5;
      background: unset;
      box-shadow: unset;
      padding: 13px 20px;
      margin: 0px 20px 15px;
      .header {
        font-size: 12px;
        color: #171a1d;
        font-weight: 600;
      }
      .list {
        margin-top: 0px;
        list-style: none;
        li {
          font-size: 12px;
          font-weight: 600;
          color: #5e6267;
        }
      }
    }
  }
}
.schedule-rw {
  .ui.celled.grid {
    margin: 0px;
    box-shadow: unset;
    .row {
      box-shadow: unset;
      .wide {
        border-bottom: 0px;
      }
    }
  }
  .ui.list {
    width: 100%;
    .item {
      width: 100%;
      margin-bottom: 16px;
      &:first-child {
        margin-top: 30px;
      }
      .header {
        border: none;
        color: #fff;
        background-color: #fff;
        height: 1px;
        background-image: linear-gradient(to right, #cac5c5 33%, #fff 0%);
        background-position: bottom;
        background-size: 5px 1px;
        background-repeat: repeat-x;
        padding: 3px 0px;
      }
      &.move-out-col {
        .description {
          background-color: #e2dcf2;
        }
      }
    }
    .content {
      float: right;
      width: calc(100% - 45px);
    }
    .description {
      background-color: #e0e0e0;
      width: 96%;
      height: 36px;
      display: flex !important;
      align-items: center;
      border-radius: 3px;
      padding: 6px;
      margin-top: -15px;
      margin: -17px auto 0px;
      font-size: 12px;
      font-weight: normal;
      color: #2e2e2e !important;
      .explore-icon {
        width: 20px;
        height: 20px;
        background-color: #fff;
        display: inline-block;
        border-radius: 3px;
        margin-right: 11px;
      }
    }
  }
}
.graph-data-wrapper { 
  &.ui.stackable.cards{
    @include mobile-tablet{
      > .card{
        padding: 20px 20px 0 !important;
        margin-bottom: 30px;
      }
    }
  }
  .ui.card {
    border: 0px !important;
    box-shadow: unset !important;
    padding: 20px !important;
    .card-header {
      padding: 0px;
      display: flex !important;
      justify-content: space-between;
      h4 {
        font-size: 15px;
        font-weight: bold !important;
        margin-bottom: 0px;
      }
      h5 {
        color: #3b1c5a;
        font-size: 17px !important;
        font-weight: 800 !important;
        margin-top: 0px;
      }
      .right-col {
        display: flex;
        span {
          color: #5e6267;
          margin-right: 10px;
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: normal;
        }
        .select-box {
          width: 130px;
          height: 35px;
          border: 1px solid #e3e3e3;
          display: flex;
          align-items: center;
          border-radius: 3px;
          justify-content: space-between;
          font-size: 12px;
          font-weight: normal;
          padding: 0px 10px;
          color: #5e6267;
          cursor: pointer;
        }
      }
    }
    .content {
      .data-list {
        background-color: #f7f7f7;
        padding: 15px 30px;
        margin: 25px 0px 25px;
        .list {
          display: flex;
          justify-content: space-between;
          .item {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            border: 0px;
            a {
              cursor: unset;
              &.header {
                font-size: 13px;
                font-weight: 600;
                color: #171a1d !important;
              }
              &.description {
                color: #5e6267;
                font-size: 11px;
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }
}

// Event tabs
.events-tab{
  padding: 20px;
  @include mobile-tablet{
    .ui.secondary.menu{
      overflow-x: auto;
      // Custom Scrollbar
      &::-webkit-scrollbar {
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #e3e0e0;
        border-radius: 3px;
      }
      .item{
        white-space: nowrap;
      }
    }
  }
  .ui.secondary.menu{
    border-radius: 3px;
    box-shadow: 0 11px 14px -10px #e5eced;
    background-color: rgba(216, 216, 216, 0.05);
    border: 0 !important;
    .item{
      flex: 1 1 auto;
      margin-bottom: 0 !important;
      justify-content: center;
      font-size: 13px;
      font-weight: 500 !important;
      color: #5e6267 !important;
      &.active, &:active{
        border-color: #3b1c5a !important;
        color: #3b1c5a !important;
      }
    }
  }
  .ui.segment{
    border: 0 !important;
    box-shadow: none !important;
    padding: 0;
  }
}
