@import "../../styles/_fonts";
@import "../../styles/quillpro/quillpro-variables";
@import "../../styles/_variables";
@import "../../styles/_card";
@import "../../styles/_button";
@import "../../styles/_base";

// These are classes used throughout different sections or impacting entire page.
// Minor padding or margins.
// - This will allow padding between all text and their associated Icons.

// // - Shifts entire center portion to the left to align with above name box.
// .left-align-center-items {
//   right: 2em;
//   bottom:0;
// }
// - Rounds the the corners of the overall Profile card.
// div.profile-page-center {
//   border-bottom-right-radius: 0.4167rem;
//   border-top-right-radius: 0.4167rem;
// }
// - Upper left of the Profile card to round border
// div.card-user-profile {
//   border-radius: 0.4167rem;
//   border-radius: 0.4167rem;
// }
// - Pad and curve the name section.
.pg-viewer-wrapper {
  // background:red !important;
  overflow: hidden !important;
  .pg-viewer {
    text-align: center !important;
    .photo {
      width: 100% !important;
      object-fit: cover;
    }
  }
}
.card-user-profile-name {
  border-top-left-radius: 0.4167rem;
  border-top-right-radius: 0.4167rem;
  // border-radius: 0.4167rem;
  padding-left: 5.8em;
}
// - Align left column endpoint information to match their respective titles.
.left-column-info {
  text-align: start;
  font-size: 1.5em;
  color: $qp-color-yellow;
}
// - Slightly adjust the header of the account edit modal.
.account-edit-modal-header {
  padding-top: 1em;
}
// - Reverses the quill gradient.
.bg-gradient,
.point-col span {
  background-image: linear-gradient(
    135deg,
    rgb(7, 167, 227),
    rgb(50, 218, 195)
  );
}

#main-tabs {
  float: right;
  position: relative;
  z-index: 1;
}
// Tables
// - Using existing quill class this pertains to the row titles of all tables
.body-font {
  font-family: "Montserrat", sans-serif !important;
  font-size: 13px;
  font-weight: bold;
  color: #4a565a;
}
// -
table.account-table {
  table-layout: fixed;
  width: 100%;
}
table.account-table td {
  word-wrap: break-word;
}

// - Align the data on the left column to be inline with above titles.
.left-info {
  text-align: center;
  font-size: 1.25em;
  color: #72848c;
}

// Icons and Tooltips
// - Places the star icon next to the users name.
.loyalty-star {
  text-align: center;
  color: $qp-color-yellow !important;
  transform: translate(0.25em, -0.75em);
}
// - Helps adjust the placement of the info "i" tooltip.
.more-info-tooltip {
  transform: translateY(0.45em);
}
// - Helps center the badges instead of being flush with other information.
.badge-margin {
  margin-left: 1em;
}
// - Color and font size change the phone icon.
.mobile-phone-icon {
  color: #72848c;
  font-size: 2.4rem;
}
// - Color and font size change the phone icon.
.home-phone-icon {
  color: #72848c;
  font-size: 1.8rem;
}
// - By default hides the trashcan
.delete-card {
  opacity: 0;
}
// - Shows the delete icon when the row is hovered over
table tr:hover .delete-card {
  opacity: 1;
}

// Hovers
// - Creates curser change on more info tooltip to question mark.
.more-info:hover {
  cursor: help;
}
// - All clickable buttons will have a different pointer on hover.
button.edit-info:hover,
.edit-info:hover,
button.add-card:hover {
  cursor: pointer;
}

// These items focus the portrait area
// - Hide the "insert photo" text and align the area with camera icon.
.photo-edit-align {
  position: absolute;
  opacity: 0;
  top: 0;
}
// - Deals with the cropper once the image is selected.
.cropper-modal {
  opacity: 0.5;
  background-color: white;
}
// - Will allow the overlay to display on profile picture.
.overlay {
  position: absolute;
  top: 6em;
  left: 0;
  background-color: $qp-color-primary;
  opacity: 0;
  height: 1.5em;
  width: 3em;
  overflow: hidden !important;
  padding: 1.2em;
}
// - Will allow for full sized overlay effect due to second layering.
.overlay-helper {
  position: absolute;
  transform: translate(-50%, -80%);
  left: 50%;
  text-align: center;
  border-radius: 0.4167rem;
}
// - Hide the overflow of the blue overlay bar.
.profile-picture {
  overflow: hidden;
}
// - Transition and position the on hover of the overlay.
.profile-picture:hover .overlay {
  opacity: 0.85;
  transition: 0.5s ease;
  display: block;
  height: 50%;
  width: 20em;
}
// - The outer border of the profile picture.
.profile-img {
  height: 9em !important;
  object-fit: cover;
  background-color: $qp-hover-highlight;
}
// - Centers the camera icon on the edit photo popup.
.centered-camera-icon {
  margin-top: 5%;
  left: 50%;
  padding-top: 7px;
}

// Buttons and Tabs
// - Help style the submit button on all modal popup submit buttons.
.modal-submit-buttons {
  width: 100%;
  color: white;
}
// - Give invisible button under the icons of add and edit.
button.add-card,
button.edit-info {
  background: none;
  border: none;
  background-color: transparent;
}
// - Set the width of the top level tabs so they match with the Profile column.
.tab-width {
  width: 8.8em;
}

#profile,
#application {
  margin-top: 1.5em;
  padding: 0px;
}
// - Sets the border radius and default text color of the top level tabs.
a.profile-tabs,
h5.profile-tabs {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  color: black;
  text-align: center;
  font-size: 1em;
}
// - Sets the color of the active tab.
a.profile-tabs.active,
h5.profile-tabs.active,
.profile-tabs.active:hover h5 {
  color: $qp-color-primary;
  background-image: linear-gradient(
    180deg,
    $qp-color-secondary,
    #f7f7f9
  ) !important;
  z-index: 1;
}
// - Sets the hover color of the top level tabs
a.profile-tabs:hover,
h5.profile-tabs:hover {
  background-color: $qp-color-primary;
  color: white;
}

.profile-tabs:hover h5 {
  color: white;
}

// .margin-application {
//   margin-top:4em;
// }

.top-tabs {
  float: left;
  background-color: #fefefe;
  border-top-left-radius: 0.4167rem;
  border-top-right-radius: 0.4167rem;
}

.card-top-tab {
  transform: translateY(1.75em);
}

// - Center page
.container-centering {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// - Sets the dimensions of the entire card for Profile.
.fixed-height-card {
  max-height: 25em;
  min-height: 25em;
}
// - Sets the overflow and dimensions of the inner card.
.table-scroll {
  max-height: 25em;
  min-height: 25em;
  overflow-y: scroll;
  position: relative;
  .tab-pane {
    padding: 0 0 2.5rem 0;
  }

  .about-me {
    &--grid {
      display: grid;
      // grid-template-columns: 1fr 1fr;
      grid-template-columns: 1fr;
      position: relative;
      padding: 0px !important;
      height: 100%;
    }
    &--words {
      // border-right:2px solid $qp-color-primary;
      // background: $qp-color-secondary;
      height: 100%;
      padding: 2rem;
      margin-bottom: 0px !important;
    }
    &--pictures {
      display: grid;
      grid-template: auto/1fr 1fr;
      gap: 1.5rem;
      padding: 2rem;
    }
  }
}
.term-agree {
  color: $qp-color-primary;
  text-decoration: underline;
}
// - Open Apps table scroll hidden
// .table-scroll::-webkit-scrollbar {
//   display:none;
// }
// - Hide the actual delete card button on the Billing Table

.billing-popover {
  // background:red;
  .popover-header {
    text-align: center;
    background: $qp-color-primary;
  }
  .popover-body {
    padding: 0px !important;
    background: $qp-color-secondary;
    display: grid;
    text-align: center;
    grid-template-columns: 1fr 1fr;
    strong {
      color: black;
      text-decoration: underline;
    }
    .right {
      padding: 5px;
      border-left: 1px solid $qp-color-primary;
    }
    .left {
      padding: 5px;
      border-right: 1px solid $qp-color-primary;
    }
  }
}
.billing-info-table {
  .accordion {
    tr {
      width: 100% !important;
    }
    .show {
      width: 100% !important;
    }
  }
  .billing {
    &-table-width {
      padding-bottom: auto;
    }
    &-header-list {
      width: 100%;
    }
    &-tab-items {
      width: 50%;
    }
    &-payments {
      padding-top: 10px;
      &-prior-title {
        text-align: center;
      }
    }
  }
  .hidden {
    display: none !important;
    visibility: none;
    background: red;
  }
  .autopayInfo {
    &-grid {
      text-align: center;
      display: grid;
      grid-template-areas:
        "t t"
        "d a";
      width: 100%;
    }
    &-date {
      grid-area: d;
    }
    &-title {
      grid-area: t;
    }
    &-amount {
      grid-area: a;
    }
  }
}
.delete-card-btn {
  background: transparent;
  border: none;
}
.auto-active {
  color: $qp-color-primary;
  border: none;
}
// - Tabs of the About/Friends/Apps to keep in place and space out.
.bio-header-list {
  background-color: #f7f7f9;
  width: 100% !important;
  padding: 0;
  margin: 0;
}
// - The div container the entire About/Friends/Apps set of tables.
.bio-info {
  width: 100% !important;
  padding: 0 !important;
}
// - The div containing the Bio Modal
.bio-header {
  width: 100% !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: 5 !important;
}
// Container setting the entire Application card's overflow
// .card-body-application {
//   overflow: auto;
// }
// - The notification icon above the Current Applications
.transform-notification {
  transform: translateY(-10px);
  margin-left: 5px;
  font-size: 8px;
  background-color: red;
}
// - Recolors the h5 tag back to black
.headline-recoloring {
  color: black;
}

// - The alert notification inside the Current Application tab next to View button
.alert-notice {
  margin-left: 10px;
}
// - Pending status in Current Application
.pendingStatus {
  color: $qp-color-yellow-dark-1;
  text-transform: uppercase;
  font-weight: bold;
}
// - Declined status in Current Application
.declinedStatus {
  color: $qp-color-danger-dark-2;
  text-transform: uppercase;
}
// - Confirmed status in Current Application
.confirmedStatus {
  color: $qp-color-success;
  text-transform: uppercase;
}

// Property Modal CSS //
// - Carousel images which sets the dimensions of the image.
.img-property {
  width: 31.25em;
  height: 320px;
}
//  - Sets the dimensions of the placeholder map
.img-map {
  width: 21.875em;
  height: 18.75em;
}
// - Sets the alignment of the Loyalty points star icon.
// .loyalty-point-star{
//   transform: translateY(-.01rem)
// }
// - h5 tags are automatically turned blue so this is to set the current h5 to black.
.duration-days {
  color: black;
}
// - This sets the pin for the places location.
.appartment-pin {
  color: #f43a59;
}
//

// - Sets the margin on the Application submit button
.background-form-btn {
  margin-top: 2em;
}
// - Aligns the Current Application table rows.
.open-app-table td {
  vertical-align: middle;
}

// Temp naming and showcase stuff
.right-move-cp {
  margin-left: 30px;
}

.middle-text {
  text-align: center;
}

.title-name-plate {
  position: absolute;
  width: 100%;
}

.overlap-plates {
  position: relative;
  margin-top: 4em;
  left: 0;
}

.image-circle {
  margin-top: 0.35em;
  margin-bottom: 0.35em;
}

.margin-application {
  margin-top: 4em;
}

.profile-newcard-modal {
  .modal-header {
    padding: 0px;
    border: 0px;
    .close {
      margin: 5px;
    }
  }
  .card-body {
    padding-left: 8px;
    padding-right: 12px;
    .show {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.auto-pay-modal {
  padding: 0px;
  .input-amount {
    // background:red;
    display: flex;
    justify-content: center;
    span {
      width: 100%;
      text-align: center;
    }
  }
  &-title {
    background: $qp-color-secondary;
  }
  &-legal {
    font-size: 12px;
  }
  .tab-content {
    .active {
      padding-bottom: 5px;
      div {
        display: flex;
        justify-content: space-between;
        input {
          width: 50%;
        }
      }
    }
  }
  .nav-tabs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    a {
      width: 50%;
      text-align: center;
      background: $qp-color-secondary;
    }
    .active {
      background: white;
      padding-bottom: 5px !important;
    }
  }
}

// - Center page
.heading.heading-md {
  font-size: 18px !important;
}

.profile-bar {
  &__grid {
    display: grid;
    gap: 1rem;

    grid-template: auto / fit-content(100%);
    grid-template-areas:
      "img nam nam nam nam"
      "img inf inf inf inf";
  }

  &__image-box {
    grid-area: img;
    z-index: 1;
    justify-self: start;
  }

  &__name-box {
    grid-area: nam;
    justify-self: left;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }

  &__info-box {
    grid-area: inf;
    display: grid;
    font-size: 1.1rem;
    text-align: center;
    color: #72848c;

    grid-template-areas: "pnt bal bad";

    .icon-pad {
      margin-right: 10px;
    }

    &--location {
      grid-area: loc;
    }

    &--points {
      grid-area: pnt;

      i {
        transform: translateY(-1px) !important;
      }

      .more-info {
        background-image: linear-gradient(
          135deg,
          rgb(7, 167, 227),
          rgb(50, 218, 195)
        );
      }
      .more-info-tooltip {
        transform: translateY(0.45em);
      }
    }

    &--balance {
      grid-area: bal;
    }

    &--badges {
      grid-area: bad;
    }
  }
}

@media (max-width: 1120px) {
  .profile-bar {
    &__grid {
      display: grid;
      gap: 1rem;

      grid-template: auto / fit-content(100%);
      grid-template-areas:
        "img nam nam nam nam"
        "img inf inf inf inf";
    }

    &__info-box {
      margin-top: 50px;
      row-gap: 5px;

      grid-template-areas:
        "bal bad"
        "pnt bad";

      &--location {
        font-size: 1rem;
      }
    }
  }
}
@media (max-width: 767px) {
  #bio-2 {
    padding: 0 1rem !important;
  }

  .account-info-table {
    padding: 0 !important;
  }

  .tab-pane {
    padding: 0px !important;
  }

  #btnGroupVerticalDrop2 {
    width: 100% !important;
    background: red;
  }

  .profile-bar {
    &__grid {
      display: grid;
      gap: 1rem;
      background: radial-gradient(
        closest-side,
        $qp-color-secondary,
        #f6f7f8,
        white
      );
      grid-template: auto / fit-content(100%);
      grid-template-areas: "img inf inf inf inf";
    }

    &__name-box {
      display: none;
    }

    &__info-box {
      margin-top: -5px;
      row-gap: 5px;

      grid-template-areas:
        "bal bad"
        "pnt bad";

      &--location {
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 550px) {
  .profile-bar {
    &__grid {
      justify-content: center;
      grid-template-areas: "inf";
    }

    &__name-box {
      display: none;
    }

    &__image-box {
      display: none;
    }

    &__info-box {
      width: 100% !important;

      justify-self: center;

      grid-template-areas:
        "bal bad"
        "pnt bad";

      gap: 1rem;
    }
  }
}

.upload-file-area {
  input {
    display: none;
  }
  label {
    cursor: pointer;
    background: #4c158a;
    color: #f2fcfb;
    padding: 10px 10px;
    font-weight: bold;
    border-radius: 4px;
    margin: 0;
    min-width: 150px;
    text-align: center;
  }
}

.photo-show-area {
  text-align: center;
  .ReactCrop {
    margin-top: 20px;
  }
}

.ui.modal > .profile-picture-modal.header {
}

.ui {
  &.modal {
    > {
      .profile-picture-modal {
        &.header {
          background: #343c49;
          color: #fff;
        }
      }
    }
  }
}

.edit-picture:hover {
  cursor: pointer;
}
.edit-picture {
  .edit-tempPic {
    max-width: 100px !important;
    max-height: 100px !important;
    width: 100px !important;
    width: 100px !important;
  }
  .edit-icon {
    color: red;
  }
}
